.about-us {
    button {
        padding: 0.8em 2em; 
        font-size: 16px;
        margin: 0 auto;
        display: block;
    }

    span {
        font-weight: 600;
        color: var(--green);
    }
}

.highlight {
    text-align: center;
    font-weight: 600;
}

.about-us__text {
    text-align: center;
    margin: 0 5vw;
}

#about-us__banner-img {
    width: 60%;
    left: 30vw;
    position: relative;
    bottom: 18vw;
}

.about-us__container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.about-us__title {
    width: 150%;
}

.about-us__mvv {
    margin: 3rem 0;
    padding: 0;
}

.mvv__container {
    display: flex;
    justify-content: space-between;
}

.mvv__card {
    display: flex;
    align-items: center;
    border-radius: 8px;
    background-color: #f3f7f9;
    padding: 40px;
    margin: 8px;

    img {
        width: 54px;
        margin: 16px 7%;
    }

    h3 {
        color: var(--blue-light);
    }

    li {
        margin: 0.5em 0;
        line-height: 150%;
    }

    ul {
        list-style: circle;
    }

    span {
        color: var(--blue-dark);
        font-weight: 500;
    }
}

.about-us__social-media {
    margin: 3rem 0;
    
    a {
        text-decoration: none;
        color: black;
        transition: all 0.2s;
    }

    a:hover {
        color: var(--blue-light)
    }

    img {
        height: 1.5em;
    }

}

.social-media__wrapper {
    display: flex;
    justify-content: center;
    gap: 36px;
}

.social-media__container {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 16px 0;
}

@media screen and (max-width: 800px) {

    .about-us__mvv,
    .about-us__container,
    .mvv__container,
    .mvv__card {
        display: block;
    }

    .mvv__card {
        img {
            margin: 0 auto;
            display: block;
        }

        h3 {
            text-align: center;
        }
    }

    .social-media__wrapper {
        display: block;
    }
}