.services {
    background: linear-gradient(144deg, rgba(6,19,128,1) 0%, rgba(4,10,61,1) 97%);
    transition: all 1s;
    padding: 0;
    margin: 0;

    h2 {
        color: white;
    }
}

.services-background {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 65%; 
    background-attachment: fixed;
    padding: 5% 0;
    /* cover for mobile */
}

.services-list {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    flex-shrink: 0;
    padding: 0 15%;
}

.service-card {
    box-sizing: border-box;
    color: white;
    border-radius: 5px;
    width: 40%;
    height: 100%;
    opacity: 1;
    margin: 2em 3vw;
    transition: scale ease-in;

    h3 {
        color: white;
    }

    p {
        color: white;
    }
}

.service-button {
    display: flex;
    bottom: 1em;
    align-items: center;
    justify-content: left;
    width: 50%;
    gap: 1em;
    color: var(--green);
    font-weight: 700;
    transition: color 0.2s;
    background: none;
    border: none;
    margin-top: 1em 0;

    input {
        display: none;
    }

    .chevronIcon {
        color: red;
        transition: all 0.5s 0.1s;
    }    
}

.service-button:hover {
    cursor: pointer;
    color: var(--green-hover);
}

.service-button:hover .chevronIcon {
    transform: translate(3px, 0);
}

.service-details {
    transform: translateY(0);  
    opacity: 0;

    img {
        width: 100%;
        border-radius: 5px;
    }

    p {
        color: var(--silver);
    }
}

hide {
    opacity: 0;
    transform: translateY(-5px);
}

.service-hidden {
    display: none;
}

.service-header {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;
    gap: 1em;
    margin-bottom: 0.5em;
    
    .service-icon {
        width: 40px;
        height: 40px;
    }
}

.service-active {
    width: 90%;

    .chevronIcon {
        transform: rotate(90deg);
    }

    .service-details {
        margin-top: 1.5em;
        transform: translateY(-5%);
        animation: show 600ms 100ms forwards;
    }

    li {
        margin: 1em 0;
        box-sizing: border-box;
    }
}

.emphasis {
    font-weight: 700;
}

.wave-top, 
.wave-bottom {
    position: relative;
    margin: 0;
    width: 100vw;
}

.wave-top {
    bottom: 5px;
}

.wave-bottom {
    top: 5px;
}

.pdf-button {
    border: none;
    border-radius: 6px;
    background-color: var(--green);
    margin: 1rem auto;
    display: block;
    font-weight: 600;
    font-size: 1.05rem;
    padding: 1rem 2rem;
    margin-top: 30px;
    cursor: pointer;
    color: var(--blue-deep);
}

.pdf-button:hover {
    background-color: var(--green-hover);
}

@keyframes show {
    100% {
      opacity: 1;
      transform: none;
    }
  }

@media screen and (max-width: 900px) {
    h2 {
        font-size: 30px;
    }

    .service-card {
        width: 100%;
        margin: 2em 0;
    }
}

@media screen and (min-width: 800px) {
    .service-active {
        h3 {
            font-size: 22px;
        }
    
        .service-icon {
            width: 50px;
            height: 50px;
        }
    }
}

