.mvv {

    margin-bottom: 4rem;

    button {
        border: none;
        border-radius: 6px;
        background-color: var(--green);
        margin: 1rem auto;
        display: block;
        font-weight: 600;
        font-size: 1.05rem;
        padding: 1rem 2rem;
    } 

    button:hover {
        background-color: #2cc832; /* Cor ao passar o mouse */
      }
      
    button:active {
        background-color: #449648; /* Cor ao clicar no botão */
        transform: translateY(2px); /* Adiciona um efeito de "pressionamento" */
    }

}
.cards-wrapper {
    display: flex;
    justify-content: center;
    gap: 2rem;
    padding: 0 12%;
}

.card{
    width: 25vw;
    padding: 3% 4%;
    border-radius: 6px;
    text-align: center;
    background-color: var(--silver);
    transition: all 0.2s;

    h3{
        color: var(--blue-light);
        font-size: 1.2rem;
        margin: 1rem 0;
        font-weight: 600;
    }

    img{
        width: 5vw;
        max-width: 65px;
        min-width: 30px;
        height: auto;
        margin: 0.7rem 0;
    }
}

.card:hover{
    transform:translateX(5px);
    transform:translateY(5px);
}


@media screen and (max-width: 800px) {
    .cards-wrapper {
        display: block;
    }

    .card {
        width: 100%;
        margin: 1rem 0;
    }
}