.portfolio {
    margin-top: 2rem;
}

.titles {
    left: 8%;
    z-index: 2;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: left;

    h2 {
        font-size: 2rem;
        font-weight: 600;
        left: 5%;
    }

    img {
        height: 2.3rem;
    }

    span {
        font-weight: 800;
    }
}

.projects_wrapper {
    display: flex;
    gap: 32px;
}

.caseDeSucesso {
    .container {
        display: flex;
        justify-content: space-between;
        gap: 8vw;
    }

    h2 {
        font-weight: 600;
        color: var(--black);
        text-align: left;
        margin: 24px 0;
        font-size: 24px;
    }
}

.link {
    color: var(--blue-light);
    font-weight: 600;
    text-decoration: none;
}

.link:hover {
    color:var(--blue-dark);
}

.caseTitulo {
    padding: 32px 12%;
    background: var(--blue-gradient);
    
    h1, h2 {
        color: white;
        text-align: left;
        margin-bottom: 8px;
    }

    h2 {
        font-weight: 400;
        font-size: 24px;
        margin-top: 160px;
    }
}

.galleryTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
        font-size: 20px;
        text-decoration: none;
        color: var(--green);
        font-weight: 600;
    }
}

.gallery {
    display: flex;
    flex-wrap:wrap;
    gap: 16px;
    align-items: center;
    justify-content: center;
    margin: 16px;
}

.galleryItem {
    width: 440px;
    height: 256px;
    border-radius: 8px;
    border: 1px solid lightgray;
    object-fit: cover;
}

@media screen and (max-width: 800px) {
    .projects_wrapper {
        flex-wrap: wrap;
    }

    .caseDeSucesso {
        .container {
            display: block;
        }
    }

    .galleryItem {
        width: 80vw;
        height: auto;
    }
}