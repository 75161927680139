.libraryContainer{
    background-color: white;
    margin: 2rem 6rem;

    .libraryTitle{
        margin: 80px 0 40px 0;
    }
    
    .libraryTitle h2{
        margin: 8px 0 8px 0;
    }
    
    .libraryTitle .titleHighlight{
        color: #297FDB
    }
}

.ebooksContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
    justify-items: center;
    margin: 0 2rem  6rem 2rem;

    .ebook {
        background-color: #050F60;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
        padding: 1.3rem 2rem;
        border-radius: 0.5rem;
        text-align: left;
        width: 17rem;
        display: flex;
        align-items: center;
        position: relative;
        overflow: visible;

        .ebookContent {
            align-items: center;
            gap: 1rem;
        
            .ebookImage {
                width: 50%; 
                height: auto;
                position: absolute; 
                left: -25px;
                object-fit: cover;
                object-position: left center;
                border-radius: 0.5rem;
                z-index: 1;
            }          
            
            .ebookText {
                flex: 1;
                margin-left: 46%;
            
                .ebookTitle {
                    color: #FFFFFF;
                    font-size: 1.25rem;
                }
                
                .ebookDescription {
                    color: #D2D2D2;
                    font-size: 0.7rem;
                }
                
                .ebookDownload{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                
                    p {
                        color: #E2E2E2;
                        font-size: 0.6rem;
                        margin: 0 auto 0.3rem auto;
                    }
                    
                    .ebookDownloadButton {
                        background-color: #297FDB;
                        color: #FFFFFF;
                        font-size: 0.75rem;
                        border: none;
                        padding: 0.5rem 2.5rem;
                        cursor: pointer;
                        transition: 0.7s;
                    }

                    .ebookDownloadButton:hover {
                        background-color: #83E356;
                        transition: 0.7s;
                    }
                }
            }
        }
    }
}

/*Responsividade*/

@media (max-width: 1517px) {
    .ebooksContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}

@media (max-width: 780px) {
    .libraryContainer{
        margin: 1rem 3rem;
    }
    .ebookTitle {
        font-size: 1rem; /* Reduzindo o tamanho do título */
    }

    .ebookDescription {
        font-size: 0.65rem;
    }

    .ebookDownload p {
        font-size: 0.55rem;
    }

    .ebookDownloadButtonBlue,
    .ebookDownloadButtonGreen {
        font-size: 0.7rem;
        padding: 0.4rem 2rem; /* Ajustando o padding para acompanhar a redução */
    }
}

@media (max-width: 620px) {
    .libraryContainer{
        margin: 0.7rem 1rem;
    }
}

@media (max-width: 550px) {

    .ebookBlue, .ebookGreen{
        min-width: 200px;
    }
    .ebookTitle {
        font-size: 0.9rem;
    }

    .ebookDescription {
        font-size: 0.6rem;
    }

    .ebookDownload p {
        font-size: 0.5rem;
    }

    .ebookDownloadButtonBlue,
    .ebookDownloadButtonGreen {
        font-size: 0.65rem;
        padding: 0.3rem 1.8rem;
    }
}