.dark {
    .item a{
        color: white;
    }
}

.header {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    display: none;
    justify-content: space-between;
    align-items: center;
    padding: 3em 12%; 
    height: 12vw;
    width: 100vw;
}

.list{
    display: flex;
    list-style: none;
    align-items: center;
}

.item a{
    color: var(--blue-dark);
    text-decoration: none;
    font-size: 18px;
    transition: all 0.5s;
    margin-right: 3em;
}


.item a:hover{
    padding-bottom: 5px;
    border-bottom: 2px solid var(--blue-light);
    /*font-size: 33px;*/
    /*text-decoration: overline;*/
    /*color: #6BB3F2;*/
}

.logoheader{
    height: 50px;
    z-index: 1;
}

.shadow{
    position: absolute;
    height: 80%;
    margin-left: -2em;
    margin-top: -1em;
}

@media  screen and (max-width: 1000px)  {
    .header {
        flex-wrap: wrap;
    }

    .item {
        margin-right: 1rem;
    }
}

@media screen and (min-width: 890px) {
    .header {
        display: flex;
    }
}